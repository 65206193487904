import { makeStyles } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles(({ constructs, palette, typography }) => ({
	inboxButton: {
		'&:hover': {
			fill: palette.primary.main,
		},
		display: 'flex',
		marginLeft: '20px',
	},
	inboxWrapper: {
		height: '24px',
		width: '26px',
	},
	countContainer: {
		position: 'absolute',
		height: ifFeature('encore', '40px'),
		width: ifFeature('encore', '40px'),
		// @startCleanup encore
		top: ifFeature('encore', undefined, '-10px'),
		right: ifFeature('encore', undefined, '-2px'),
		// @endCleanup encore
		'&:hover': {
			'& $count': {
				borderColor: ifFeature('encore', palette.gray[100]),
			},
		},
	},
	count: {
		backgroundColor: palette.primary.main,
		position: 'absolute',
		right: ifFeature('encore', '5px', '-14px'),
		top: ifFeature('encore', '-5px', '3px'),
		fontSize: ifFeature('encore', '10px', '11px'),
		border: '2px solid transparent',
		borderColor: ifFeature(
			'encore',
			constructs.border.neutral.forcedWhite,
			palette.gray[200],
		),
		color: ifFeature('encore', constructs.text.neutral.forcedWhite, 'white'),
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '50%',
		fontWeight: typography.fontWeightHeavy,
		width: ifFeature('encore', '22px', '20px'),
		height: ifFeature('encore', '22px', '20px'),
		// @startCleanup encore
		lineHeight: ifFeature('encore', undefined, '1.1'),
		fontFamily: ifFeature('encore', undefined, 'Lato'),
		// @endCleanup encore
		textIndent: 0,
	},
	activeCount: {
		borderColor: ifFeature('encore', palette.gray[100]),
	},
}));
