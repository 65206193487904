import React from 'react';
import { ActionBanner, Button, IconButton, IconV2 } from '@bamboohr/fabric';
import {
	AwardRibbon36x27,
	Gauge40x34,
	Stopwatch34x40,
	X14x14,
} from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { SetupSummaryBannerProps } from './types';

export function CoreImplementationBanner(props: SetupSummaryBannerProps) {
	const { biId, buttonText, onClose, primaryUrl, subtitle, title } = props;

	let bannerIcon;
	switch (title) {
		case 'Total Rewards':
			bannerIcon = ifFeature(
				'encore',
				<IconV2 name="award-regular" size={60} />,
				<AwardRibbon36x27 />,
			);
			break;
		case 'Project Pay':
			bannerIcon = ifFeature(
				'encore',
				<IconV2 name="stopwatch-regular" size={60} />,
				<Stopwatch34x40 />,
			);
			break;
		default:
			bannerIcon = ifFeature(
				'encore',
				<IconV2 name="gauge-high-regular" size={60} />,
				<Gauge40x34 />,
			);
	}

	return (
		<ActionBanner
			actions={[
				<Button
					dark
					data-bi-id={
						biId
							? `${biId}-coreImplementationSummaryBanner-backToCoreImplementation`
							: null
					}
					href={primaryUrl}
					key="primary"
					type="button"
				>
					{buttonText}
				</Button>,
				<IconButton
					ariaLabel={$.__('Close Core Implementation banner.')}
					color="secondary"
					dark
					/* @startCleanup encore */
					floatingIcon={ifFeature('encore', undefined, true)}
					/* @endCleanup encore */
					icon={ifFeature('encore', 'xmark-solid', <X14x14 />)}
					key="close"
					/* @startCleanup encore */
					noBoundingBox={ifFeature('encore', undefined, true)}
					/* @endCleanup encore */
					onClick={onClose}
					type="button"
				/>,
			]}
			ariaLabel={$.__('Core Implementation banner.')}
			biId={biId}
			image={bannerIcon}
		>
			<ActionBanner.Title subTitle={subtitle} title={title} />
		</ActionBanner>
	);
}
