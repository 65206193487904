import { makeStyles, Theme } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles<Theme, { bannerOffset: number }>(
	(theme: Theme) => {
		const { borderRadiuses, constructs, mixins, palette } = theme;
		// @startCleanup encore
		const focusRingStyles = ifFeature(
			'encore',
			{},
			mixins.focusRing(palette.primary),
		);
		// @endCleanup encore

		return {
			root: {},
			loader: {
				alignSelf: 'center',
				padding: '42px 0',
			},
			results: {
				border: ifFeature(
					'encore',
					`1px solid ${constructs.border.neutral.weak}`,
				),
				borderRadius: ifFeature('encore', borderRadiuses[200], 2),
				boxShadow: ifFeature(
					'encore',
					mixins.boxShadow('700', palette.gray[900]),
				),
				maxHeight: ({ bannerOffset }) =>
					ifFeature(
						'encore',
						`calc(100vh - var(--fabric-global-header-height, 91px) - ${bannerOffset}px)`,
						'calc(100vh - 85px)',
					),
				width: ifFeature('encore', 380, 394),
				// @startCleanup encore
				...focusRingStyles,
				// @endCleanup encore
				backgroundColor: palette.common.white,
				display: 'flex',
				flexDirection: 'column',
				flexShrink: 1,
				overflow: 'hidden',
				zIndex: 31,
			},
		};
	},
);
