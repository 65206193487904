import {
	ClickAwayListener,
	HeaderSearchInput,
	Loader,
	Popper,
} from '@bamboohr/fabric';
import React, { ChangeEvent, useRef, useState } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { preventKeys, useEscapeKey, useDebounce } from './utils';
import { Results } from './results';
import { useSearchApi } from './use-search-api';
import { useStyles } from './styles';
import { useSearchHotkey } from './use-search-hotkey';

export const Search = () => {
	const ref = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);

	const bannerOffset =
		document.getElementById('js-BannerContainer')?.offsetHeight || 0;

	const classes = useStyles({ bannerOffset });

	const [open, setOpen] = useState(false);
	const [value, setValue] = useState('');

	const searchText = useDebounce(value, 500);

	const { results, isPosting } = useSearchApi(searchText);

	const clearValueAndCloseModal = () => {
		setOpen(false);
		setValue('');
	};

	useEscapeKey(() => clearValueAndCloseModal(), open);

	useSearchHotkey(() => inputRef.current?.focus());

	const handleFocus = () => {
		if (value) {
			setOpen(true);
		}
	};

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		const _value = event.currentTarget?.value;

		setOpen(!!_value);
		setValue(_value);
	};

	const isLoading = isPosting || value !== searchText;

	return (
		<ClickAwayListener onClickAway={() => setOpen(false)}>
			<div ref={ref}>
				<HeaderSearchInput
					hasVisibleResults={open}
					onChange={handleInputChange}
					onClear={clearValueAndCloseModal}
					onFocus={handleFocus}
					onKeyDown={preventKeys(['ArrowUp', 'ArrowDown'])}
					ref={inputRef}
					value={value}
				/>
				<Popper
					anchorEl={ref.current}
					className={classes.results}
					open={open}
					placement={ifFeature('encore', 'bottom-start', 'bottom-end')}
					popperOptions={{
						modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
					}}
				>
					{isLoading ? (
						<div className={classes.loader}>
							<Loader small={true} />
						</div>
					) : (
						<Results
							data={results}
							keyboardTarget={inputRef}
							searchText={searchText}
						/>
					)}
				</Popper>
			</div>
		</ClickAwayListener>
	);
};
