import { MenuItemText } from '@bamboohr/fabric';
import React, { FC, Fragment } from 'react';
import { JobOpeningResultData } from '../../types';

export type JobOpeningResultProps = {
	data: JobOpeningResultData;
};

export const JobOpeningResult: FC<JobOpeningResultProps> = (props) => {
	const { age, name, status } = props.data;

	return (
		<MenuItemText
			primary={name}
			secondary={
				<Fragment>
					{status}
					<br />
					{age}
				</Fragment>
			}
		/>
	);
};
