import React from 'react';
import { Banner } from '@bamboohr/fabric';
import { Message } from '@bamboohr/utils/lib/message';
import { PersonCircle24x24 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function SignedInAsBanner() {
	const { firstName, lastName, supportAdmin } = window.SESSION_USER;

	return (
		<Banner
			icon={ifFeature('encore', 'circle-user-solid', <PersonCircle24x24 />)}
		>
			<Message
				params={[window.SESSION_COMPANY.name, `${firstName} ${lastName}`]}
				text={
					supportAdmin
						? $._('You are logged into **{1}**.')
						: $._('You are logged into **{1}** on behalf of **{2}**.')
				}
			/>
		</Banner>
	);
}
