import React, { FC } from 'react';
import { PoMicroFrontend } from 'micro-frontend.react';
import { SiteCanSee } from '../../types';
import { AccountMenu } from '../../account-menu';
import { HelpMenu } from './help-menu';
import { Inbox } from './inbox';
import { Settings } from './settings';
import { useStyles } from './styles';

type ActionProps = {
	canSee: SiteCanSee;
	learningLink: string;
	helpCenterLink: string;
};

/**
 * @deprecated
 * Once encore is on, this will not be used
 * See `get-header-actions.tsx` for the new implementation
 */

export const Actions: FC<ActionProps> = (props) => {
	const classes = useStyles(props);

	const { canSee, learningLink, helpCenterLink } = props;

	return (
		<div className={classes.actions}>
			{canSee.inbox && <Inbox />}
			<HelpMenu helpCenterLink={helpCenterLink} learningLink={learningLink} />
			{canSee.settings && <Settings />}
			<AccountMenu canSee={canSee} />
			{canSee.answers && <PoMicroFrontend route='/answers/header-button' />}
		</div>
	);
};
