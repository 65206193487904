import { ItemType } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { SiteCanSee } from '../types';

export const useAccountItems = (canSee: SiteCanSee): Array<ItemType> => {
	const id = window.SESSION_USER.id;

	const subItems: Array<ItemType> = [];

	if (canSee.apps) {
		subItems.push({
			value: 'app-integrations',
			href: 'https://marketplace.bamboohr.com',
			text: $.__('App Integrations'),
		});
	}

	if (canSee.devices && canSee.api) {
		subItems.push({
			value: 'devices-and-keys',
			href: `/settings/permissions/api.php?id=${id}`,
			text: $.__('My Devices & API Keys'),
		});
	} else if (canSee.devices) {
		subItems.push({
			value: 'devices-and-keys',
			href: `/settings/permissions/api.php?id=${id}`,
			text: $.__('My Devices'),
		});
	} else if (canSee.api) {
		subItems.push({
			value: 'devices-and-keys',
			href: `/settings/permissions/api.php?id=${id}`,
			text: $.__('API Keys'),
		});
	}

	if (canSee.mobileQrLogin) {
		subItems.push({
			isActionOnly: true,
			text: $.__('Mobile App QR Code Login'),
			value: 'mobile-qr-login',
		});
	}

	if (canSee.devUtilities) {
		subItems.push({
			value: 'dev-utilities',
			href: '/dev',
			text: 'Dev Utilities (Dev Only)',
		});
		let searchString = window.location.search;
		if (searchString.includes('fabricTheme')) {
			if (searchString.includes('fabricTheme=encore')) {
				searchString = searchString.replace(
					'fabricTheme=encore',
					'fabricTheme=jade',
				);
			} else if (searchString.includes('fabricTheme=jade')) {
				searchString = searchString.replace(
					'fabricTheme=jade',
					'fabricTheme=encore',
				);
			}
		} else {
			searchString =
				searchString === ''
					? `?fabricTheme=${ifFeature('encore', 'jade', 'encore')}`
					: `${window.location.search}&fabricTheme=${ifFeature(
							'encore',
							'jade',
							'encore',
					  )}`;
		}

		subItems.push({
			value: 'toggle-encore',
			href: `${searchString}`,
			text: 'Toggle Encore (Dev Only)',
		});
	}

	const userSettingItems = [
		{
			value: 'account-settings',
			href: '/settings/users/account_settings',
			text: $.__('Account Settings'),
		},
		{
			value: 'change-password',
			href: '/dashboard/password.php',
			text: $.__('Change Password'),
		},
	];

	if (canSee.twoStepSelfService) {
		userSettingItems.push({
			value: '2-step-login',
			href: ifFeature(
				'encore',
				'/app/settings/users/mfa_settings',
				'/settings/users/mfa_settings',
			),
			text: $.__('2-Step Login'),
		});
	}

	const items: Array<ItemType> = [
		{
			items: userSettingItems,
			text: '',
			type: 'group',
			key: 1,
		},
		{
			items: subItems,
			text: '',
			type: 'group',
			key: 2,
		},
		{
			items: [
				{
					value: 'logout',
					href: '/login.php?logout=1',
					text: $.__('Log Out'),
				},
			],
			text: '',
			type: 'group',
			key: 3,
		},
	];

	if (!subItems.length) {
		items.splice(1, 1);
	}

	return items;
};
