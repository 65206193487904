import React, { FC } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Gear24x26 } from '@bamboohr/grim';
import { IconButton, makeStyles, IconV2 } from '@bamboohr/fabric';

const useStyles = makeStyles(({ palette }) => ({
	settingsButton: {
		'&:hover': {
			fill: palette.primary.main,
		},
		display: 'flex',
		marginLeft: '20px',
	},
}));

export const Settings: FC = (props) => {
	const classes = useStyles(props);

	const active = !!window.location.href.match(/\.com(\/app)?\/settings/);
	const iconName = active ? 'gear-solid' : 'gear-regular';

	return (
		<IconButton
			active={active}
			className={ifFeature('encore', '', classes.settingsButton)}
			data-bi-id="header-settings-link"
			floatingIcon={true}
			href="/settings/"
			icon={ifFeature(
				'encore',
				<IconV2 name={iconName} size={24} />,
				<Gear24x26 />,
			)}
			noBoundingBox
			title={$.__('Settings')}
		/>
	);
};
