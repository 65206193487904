import React, { FC, useEffect, useState } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import {
	Inbox26x24,
	Inbox15x14,
	PaperAirplane14x14,
	ClockReverse14x13,
} from '@bamboohr/grim';
import {
	Menu,
	IconButton,
	ItemType,
	IconV2,
	Header as FabricHeader,
	BodyText,
} from '@bamboohr/fabric';
import { useStyles } from './styles';
import { getInboxData } from './utils';
import clsx from 'classnames';

export const Inbox: FC = (props) => {
	const classes = useStyles(props);

	const [open, setOpen] = useState(false);
	const [count, setCount] = useState<number | null>(null);
	const [isWidgetEnabled, setIsWidgetEnabled] = useState<boolean>();
	const [inboxItems, setInboxItems] = useState<Array<ItemType>>([]);

	const items: Array<ItemType> = [
		{
			items: [
				{
					value: 'inbox',
					icon: ifFeature(
						'encore',
						<IconV2 name="inbox-regular" size={12} />,
						<Inbox15x14 />,
					),
					href: '/inbox/',
					text: 'Inbox',
				},
			],
			text: '',
			type: 'group',
			key: 1,
		},
		{
			items: Object.values(inboxItems),
			text: '',
			type: 'group',
			key: 2,
		},
		{
			items: [
				{
					value: 'requests',
					icon: ifFeature(
						'encore',
						<IconV2 name="paper-plane-regular" size={12} />,
						<PaperAirplane14x14 />,
					),
					href: '/inbox/sent_requests/',
					text: 'Sent Requests',
				},
			],
			text: '',
			type: 'group',
			key: 3,
		},
	];

	useEffect(() => {
		const fetchCount = async () => {
			try {
				const { counts, isEmployeeCommunityWidgetEnabled, subsections } =
					await getInboxData();

				setIsWidgetEnabled(isEmployeeCommunityWidgetEnabled);

				if (counts) {
					let countDisplay;
					const dataCount = counts > 0 ? counts : null;
					if (dataCount && dataCount > 99) {
						countDisplay = '99+';
					} else {
						countDisplay = dataCount;
					}
					setCount(countDisplay);
				}

				if (subsections) {
					const dataItems = subsections.map((entry): ItemType => {
						return {
							text: `${entry.label} (${entry.count})`,
							href: entry.href,
							value: entry.label,
						};
					});
					setInboxItems(dataItems);
				}
			} catch (error) {
				setMessage('There was a problem getting inbox data', 'error');
				throw error;
			}
		};

		fetchCount();
		window.addEventListener('inbox:reload', fetchCount);

		return () => {
			window.removeEventListener('inbox:reload', fetchCount);
		};
	}, []);

	if (isWidgetEnabled) {
		items.push({
			value: 'past_announcements',
			icon: ifFeature(
				'encore',
				<IconV2 name="clock-rotate-left-regular" size={12} />,
				<ClockReverse14x13 />,
			),
			href: '/dashboard/announcements/',
			text: $.__('Past Announcements'),
			key: 4,
		});
	}

	const active = !!window.location.href.match(/\.com(\/app)?\/inbox/);

	const notificationIcon = (
		<span className={classes.countContainer}>
			<span
				className={clsx(classes.count, {
					[classes.activeCount]: active,
				})}
			>
				{ifFeature('encore', <BodyText>{count}</BodyText>, count)}
			</span>
		</span>
	);

	return (
		<Menu
			biId="site-manage-nav-inbox"
			isOpen={open}
			items={items}
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			placement={{ side: 'bottom', align: ifFeature('encore', 'end', 'start') }}
			renderToggle={(options, buttonProps) =>
				ifFeature(
					'encore',
					<FabricHeader.Action
						{...buttonProps}
						active={active}
						activeIcon="inbox-solid"
						data-bi-id="header-inbox"
						focused={open}
						icon="inbox-regular"
						key="inbox"
						label="Inbox"
					>
						{count === null ? null : notificationIcon}
					</FabricHeader.Action>,
					<IconButton
						{...buttonProps}
						active={active}
						className={ifFeature('encore', undefined, classes.inboxButton)}
						floatingIcon={true}
						icon={
							<div className={classes.inboxWrapper}>
								<Inbox26x24 />
								{count === null ? null : notificationIcon}
							</div>
						}
						noBoundingBox
						title={$.__('Inbox')}
					/>,
				)
			}
		/>
	);
};
