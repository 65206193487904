import { makeStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles(({ palette }) => ({
	actions: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%',
	},
	settingsButton: {
		'&:hover': {
			fill: palette.primary.main,
		},
		display: 'flex',
		marginLeft: '20px',
	},
}));
