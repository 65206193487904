import React from 'react';
import { ActionBanner, Button, IconButton, IconV2 } from '@bamboohr/fabric';
import { RacecarFlag48x53, X14x14 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { SetupSummaryBannerProps } from './types';

export function QuickStartBanner(props: SetupSummaryBannerProps) {
	const { biId, onClose, progress, progressNote, primaryUrl, title } = props;

	let note;
	if (progress && progressNote) {
		note = `${progress.current}/${progress.total} ${progressNote}`;
	}

	return (
		<ActionBanner
			actions={ifFeature(
				'encore',
				[
					<Button
						dark
						data-bi-id={
							biId ? `${biId}-quickstartSummaryBanner-backToQuickstart` : null
						}
						href="/app/setup/quickstart"
						key="primary"
						type="button"
					>
						{$.__('Back To QuickStart')}
					</Button>,
					<Button
						color="secondary"
						dark
						data-bi-id={
							biId ? `${biId}-quickstartSummaryBanner-viewLearningCourse` : null
						}
						href={primaryUrl}
						key="secondary"
						target="_blank"
						type="button"
					>
						{$.__('View Learning Course')}
					</Button>,
					<IconButton
						ariaLabel={$.__('Close Quick Start banner.')}
						color="secondary"
						dark
						icon="xmark-solid"
						key="close"
						onClick={onClose}
						type="button"
					/>,
				],
				[
					<Button
						color="secondary"
						dark
						data-bi-id={
							biId ? `${biId}-quickstartSummaryBanner-viewLearningCourse` : null
						}
						href={primaryUrl}
						key="secondary"
						target="_blank"
						type="button"
						variant="outlined"
					>
						{$.__('View Learning Course')}
					</Button>,
					<Button
						dark
						data-bi-id={
							biId ? `${biId}-quickstartSummaryBanner-backToQuickstart` : null
						}
						href="/app/setup/quickstart"
						key="primary"
						type="button"
					>
						{$.__('Back To QuickStart')}
					</Button>,
					<IconButton
						ariaLabel={$.__('Close Quick Start banner.')}
						color="secondary"
						dark
						floatingIcon={true}
						icon={<X14x14 />}
						key="close"
						noBoundingBox={true}
						onClick={onClose}
						type="button"
					/>,
				],
			)}
			ariaLabel={$.__('QuickStart banner.')}
			biId={biId}
			image={ifFeature(
				'encore',
				<IconV2 name="flag-checkered-solid" size={60} />,
				<RacecarFlag48x53 />,
			)}
		>
			<ActionBanner.Title subTitle={note} title={title} />
		</ActionBanner>
	);
}
