import React, { ReactElement } from 'react';

import { MagGlassBox28x28 } from '@bamboohr/grim';
import { IconV2 } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import { useStyles } from './styles';

export type NoResultsProps = {
	searchText: string;
};

export const NoResults = (props: NoResultsProps): ReactElement => {
	const { searchText } = props;
	const classes = useStyles(props);

	return (
		<div className={classes.root}>
			{ifFeature(
				'encore',
				<IconV2 name="magnifying-glass-regular" size={28} />,
				<MagGlassBox28x28 className={classes.icon} />,
			)}
			<div className={classes.text}>
				{$.__('No matches for %s', `"${searchText}"`)}
			</div>
		</div>
	);
};
