import { MenuItemText } from '@bamboohr/fabric';
import React, { FC } from 'react';
import { ReportResultData } from '../../types';

export type ReportResultProps = {
	data: ReportResultData;
};

export const ReportResult: FC<ReportResultProps> = (
	props: ReportResultProps,
) => {
	const { name } = props.data;
	return <MenuItemText primary={name} />;
};
