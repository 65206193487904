import { Avatar, MenuItemAvatar, MenuItemText } from '@bamboohr/fabric';
import React, { FC, Fragment } from 'react';
import { JobCandidateResultData } from '../../types';

export type JobCandidateResultProps = {
	data: JobCandidateResultData;
};

export const JobCandidateResult: FC<JobCandidateResultProps> = (props) => {
	const { applicationAge, firstName, lastName, positionName, status } =
		props.data;
	return (
		<Fragment>
			<MenuItemAvatar>
				<Avatar />
			</MenuItemAvatar>
			<MenuItemText
				primary={`${firstName} ${lastName}`}
				secondary={
					<Fragment>
						{positionName}
						<br />
						{status} {applicationAge}
					</Fragment>
				}
			/>
		</Fragment>
	);
};
