import { LayoutEscapeHatch, createStyles, makeStyles } from '@bamboohr/fabric';
import React, { ReactElement } from 'react';
import { GracePeriodBanner } from './grace-period';
import { PreviewAsBanner } from './preview-as';
import { SignedInAsBanner } from './signed-in-as';
import { DemoDataAndDaysInTrialBanner } from './demo-data-and-days-in-trial-data';
import { HeaderBannerProps } from './types';
import { AnnouncementBanner } from './announcement';
import { CompleteNewHirePacketBanner } from './complete-new-hire-packet/index';
import { PaymentMethodExpiresBanner } from './payment-method-expires';
import { SetupBanners } from './setup';
import { CancellationNoticeBanner } from './cancellation-notice';

const useStyles = makeStyles(() =>
	createStyles({
		previewAsHeader: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
	}),
);

export function HeaderBanners({
	announcementData,
	gracePeriodData,
	paymentMethodExpiresData,
	PreviewAsBannerData,
	showSignedInAsBanner,
	demoDataAndDaysInTrialData,
	completeNewHirePacketData,
	cancellationNoticeData,
}: HeaderBannerProps): ReactElement {
	const styles = useStyles();

	return (
		<LayoutEscapeHatch
			getStyles={{
				'@media print': {
					display: 'none',
				},
			}}
			reason="PRINT_STYLES"
		>
			{/* The order of these banners is important when there are multiple banners visible they will stack and we want them in a specific order. */}
			{/* @startCleanup encore */}
			{/* The order should match the order they are in header.html.twig in the Main repo. */}
			{/* Once Jade goes away though all of the banners in Main will get removed in the cleanup. */}
			{/* @endCleanup encore  */}
			{paymentMethodExpiresData && (
				<PaymentMethodExpiresBanner {...paymentMethodExpiresData} />
			)}
			{gracePeriodData.showGracePeriodBanner && (
				<GracePeriodBanner {...gracePeriodData} />
			)}
			{announcementData && <AnnouncementBanner {...announcementData} />}
			{showSignedInAsBanner && <SignedInAsBanner />}
			{cancellationNoticeData && (
				<CancellationNoticeBanner {...cancellationNoticeData} />
			)}
			{completeNewHirePacketData ? (
				<CompleteNewHirePacketBanner {...completeNewHirePacketData} />
			) : null}
			{PreviewAsBannerData ? (
				<div className={styles.previewAsHeader}>
					<PreviewAsBanner {...PreviewAsBannerData} />
				</div>
			) : null}
			{demoDataAndDaysInTrialData ? (
				<DemoDataAndDaysInTrialBanner {...demoDataAndDaysInTrialData} />
			) : null}
			{/* SetupBanners internally determines whether to render anything */}
			<SetupBanners />
		</LayoutEscapeHatch>
	);
}
