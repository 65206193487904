import axios from 'axios';
import React, { useState } from 'react';
import { Banner } from '@bamboohr/fabric';
import { Megaphone23x24 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { toFormData } from '../utils';
import { AnnouncementBannerProps } from './types';

export function AnnouncementBanner({ id, message }: AnnouncementBannerProps) {
	const [isOpen, setIsOpen] = useState(true);

	function onCloseBanner() {
		axios.post(
			'/ajax/announcement.php',
			toFormData({
				action: 'read',
				id,
			}),
		);
		setIsOpen(false);
	}

	return (
		<Banner
			icon={ifFeature('encore', 'bullhorn-solid', <Megaphone23x24 />)}
			onClose={onCloseBanner}
			open={isOpen}
		>
			{message}
		</Banner>
	);
}
