import { makeStyles } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles(({ palette, spacing }) => ({
	icon: {
		'&:hover': {
			fill: palette.primary.main,
		},
		marginLeft: '20px',
		display: 'flex',
	},
	header: {
		background: palette.gray[100],
		display: 'flex',
		padding: ifFeature('encore', spacing(0.25, 2), spacing(0.25, 1)),
		flexGrow: 1,
	},
}));
