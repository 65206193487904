import { MenuItemText } from '@bamboohr/fabric';
import React, { FC } from 'react';
import { FileResultData } from '../../types';

export type FileResultProps = {
	data: FileResultData;
};

export const FileResult: FC<FileResultProps> = (props) => {
	const { name } = props.data;
	return <MenuItemText primary={name} />;
};
