import { getSearchHelpData } from './get-search-help-data';
import { ItemType } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const getSearchItems = async (
	searchParam: string,
): Promise<Array<ItemType>> => {
	return await getSearchHelpData(searchParam).then((responses) => {
		return responses.hits.map((hit): ItemType => {
			if (hit.type === 'pendo') {
				return {
					icon: ifFeature(
						'encore',
						'circle-play-regular',
						'fab-play-circle-14x14',
					),
					text: hit.name,
					href: `/home/?pendo=${hit.objectID}`,
					value: hit.objectID,
				};
			} else {
				return {
					icon: ifFeature('encore', 'file-lines-regular', 'fab-file-12x14'),
					text: hit.name,
					href: hit.url,
					value: hit.objectID,
				};
			}
		});
	});
};
