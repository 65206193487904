import React, { Fragment, useState } from 'react';
import {
	Banner,
	BannerLink,
	BodyText,
	Flex,
	TextButton,
} from '@bamboohr/fabric';
import { CompleteNewHirePacketProps } from './types';
import { DisabledForPreviewingModal } from './disabled-for-previewing-modal';
import { ifFeature } from '@bamboohr/utils/lib/feature';

/**
 * @startCleanup encore
 */
import { HiBubble24x24 } from '@bamboohr/grim';
/**
 * @endCleanup encore
 */

export function CompleteNewHirePacketBanner(data: CompleteNewHirePacketProps) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<Fragment>
			<Banner icon={ifFeature('encore', 'hand-wave-solid', <HiBubble24x24 />)}>
				<BodyText inline>
					{$.__(
						'Hi %s, you still need to complete your New Hire Packet.',
						data.prefFirstName,
					)}
				</BodyText>{' '}
				{window.GLOBAL_IS_ASSUMED_USER ? (
					<TextButton dark inline onClick={() => setIsOpen(true)}>
						{ifFeature(
							'encore',
							$.__('Complete it now') + ' »',
							<BodyText component="span" weight="bold">
								{$.__('Complete it now')} »
							</BodyText>,
						)}
					</TextButton>
				) : (
					<BannerLink href="/employees/new_hire_packet_login.php">
						{$.__('Complete it now')} »
					</BannerLink>
				)}
			</Banner>
			<DisabledForPreviewingModal
				isOpen={isOpen}
				onRequestClose={() => setIsOpen(false)}
			/>
		</Fragment>
	);
}
