import {
	Avatar,
	Button,
	GlobalNavigation as FabricGlobalNavigation,
	IconButton,
	ItemType,
	Menu,
	Tooltip,
} from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import React, { FC, useState } from 'react';
import { PoMicroFrontend } from 'micro-frontend.react';
import { SiteCanSee } from '../types';
import { useStyles } from './styles';
import { useAccountItems } from './use-account-items';

type AccountMenuProps = {
	canSee: SiteCanSee;
};

export const AccountMenu: FC<AccountMenuProps> = (props) => {
	const classes = useStyles();
	const { canSee } = props;

	const [open, setOpen] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const userSession = window.SESSION_USER;
	const [footerItemRef, setFooterItemRef] = useState<HTMLLIElement | null>();

	const items: Array<ItemType> = useAccountItems(canSee);

	const onMenuItemSelect = (item: ItemType): void => {
		if (item.value === 'mobile-qr-login') {
			setIsModalOpen(true);
		}
	};

	const dropdown = (
		<div className={classes.header}>
			<Avatar
				alt={userSession.preferredName}
				className={classes.largeAvatar}
				src={userSession.avatarMedium}
				variant="circular"
			/>
			<div>
				<span className={classes.name}>{`${
					userSession.preferredName ?? userSession.firstName
				} ${userSession.lastName}`}</span>
				<Button
					className={classes.button}
					href={`/employees/employee.php?id=${userSession.employeeId}`}
					size="teenie"
				>
					{$.__('My Info')}
				</Button>
			</div>
		</div>
	);

	const accountMenu = ifFeature(
		'encore',
		<FabricGlobalNavigation.FooterItem
			ariaLabel={$.__('My Account Menu')}
			containerRef={setFooterItemRef}
		>
			<Tooltip
				anchorEl={footerItemRef}
				content={$.__('My Account')}
				enterDelay={150}
				placement="right"
			/>
			<Menu
				isOpen={open}
				items={items}
				onClose={() => setOpen(false)}
				onOpen={() => setOpen(true)}
				onSelect={onMenuItemSelect}
				placement={{ side: 'bottom', align: 'start' }}
				renderToggle={(options, buttonProps) => {
					return (
						<FabricGlobalNavigation.FooterItemButton {...buttonProps}>
							<Avatar
								alt={userSession.preferredName}
								size={32}
								src={userSession.avatarMedium}
							/>
						</FabricGlobalNavigation.FooterItemButton>
					);
				}}
			>
				{dropdown}
			</Menu>
		</FabricGlobalNavigation.FooterItem>,
		<Menu
			isOpen={open}
			items={items}
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			onSelect={onMenuItemSelect}
			placement={{ side: 'bottom', align: 'start' }}
			renderToggle={(options, buttonProps) => (
				<IconButton
					{...buttonProps}
					className={classes.accountMenu}
					floatingIcon={true}
					icon={
						<Avatar
							alt={userSession.preferredName}
							className={classes.tinyAvatar}
							src={userSession.avatarTiny}
							variant="circular"
						/>
					}
					noBoundingBox
					title={$.__('Account Menu')}
				/>
			)}
		>
			{dropdown}
		</Menu>,
	);

	return canSee.account ? (
		<>
			{accountMenu}
			<PoMicroFrontend
				route='/site/mobile-qr-login'
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
			/>
		</>
	) : (
		<h5 className={classes.expired}>Hi, {userSession.firstName}</h5>
	);
};
